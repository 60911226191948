import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CUSTOM_FIELD_TYPE_USER_ATTRIBUTE, MAX_FILE_SIZE_MB } from 'src/app/constants';
import { Upload } from 'src/models';
import { UploadService } from 'src/services/upload.service';

@Component({
  selector: 'app-response-input',
  templateUrl: './response-input.component.html',
  styleUrls: ['./response-input.component.scss'],
})
export class ResponseInputComponent implements OnInit {
  @Input() responseInputFields: any[] = [];
  @Input() displayStyle = 'default';
  @Input() layout: 'default' | 'list' = 'default';
  @Input() showPublicOption: boolean;
  @Input() isReadOnly: boolean;
  @Output() didChange = new EventEmitter();

  uploadingAtIndex = -1;

  constructor(private uploadSrvc: UploadService) {}

  ngOnInit() {
    this.responseInputFields = this.responseInputFields.filter(
      (field) => field.type !== CUSTOM_FIELD_TYPE_USER_ATTRIBUTE,
    );
  }

  isLink(text: string) {
    return text.startsWith('http://') || text.startsWith('https://');
  }

  getLabelForFilename(input: string) {
    // Will be in the format {url}/{tenantId}/user_uploads/{filename}?alt=media
    const filename = decodeURIComponent(input).split('/');

    return filename[filename.length - 1].split('?')[0];
  }

  downloadFile(url: string) {
    window.open(url, '_blank');
  }

  didToggleCheckbox(item: any) {
    let val = '';

    item.options.forEach((option) => {
      if (option.selected) {
        val = `${val}${val.length ? ', ' : ''}${option.label}`;
      }
    });

    item.value = val;
  }

  async detectResponseUpload(event: any, index: number) {
    const files = event.target.files;

    if (files.length) {
      this.uploadingAtIndex = index;

      const file = event.target.files.item(0);

      if (file.size / 1000 / 1000 > MAX_FILE_SIZE_MB) {
        console.log('Files should not be larger than 5mb; try something smaller.');
        return;
      }

      const currentUpload = new Upload(file);

      this.uploadSrvc
        .upload([currentUpload], `user_uploads`)
        .then(() => {
          this.responseInputFields[this.uploadingAtIndex].value = currentUpload.url;
          this.uploadingAtIndex = -1;
        })
        .catch((err) => {
          console.log('Error uploading: ', err);
          this.uploadingAtIndex = -1;
        });
    }
  }
}
