<div *ngIf="layout === 'default'">
  <div *ngFor="let item of responseInputFields; let i = index" [ngClass]="{ 'ion-card': displayStyle === 'card' }">
    <ion-row class="question-input">
      <!-- Checkbox input -->
      <ion-checkbox
        *ngIf="item.type === 'checkbox'"
        color="light"
        [ngModel]="!!item.value"
        (ngModelChange)="item.value = $event"
        class="question-checkbox"
      >
      </ion-checkbox>

      <span *ngIf="item.type === 'checkbox'" (click)="item.value = !item.value" class="clickable">
        {{ item.title }}
      </span>
      <span *ngIf="item.type !== 'checkbox'" class="input-label">
        {{ item.title }}
      </span>

      <span
        *ngIf="item.required"
        class="question-accessory"
        style="color: #a70707"
        ngxTippy
        data-tippy-content="Response required"
        >*</span
      >

      <span
        *ngIf="showPublicOption && item.public === false"
        class="question-accessory"
        ngxTippy
        data-tippy-content="Response won't be shared on your profile"
      >
        <ion-icon name="eye-off-outline"></ion-icon>
      </span>
    </ion-row>
    <ion-row>
      <!-- Short answer input -->
      <ion-input
        *ngIf="!item.type || item.type === 'short-answer' || item.type === 'user-attribute'"
        placeholder="{{ item.required === true ? 'Required' : 'Optional' }}"
        class="input-field"
        mode="md"
        [(ngModel)]="item.value"
        [disabled]="item.fieldKey"
        autocapitalize="sentences"
      >
      </ion-input>

      <!-- Long answer input -->
      <ion-textarea
        *ngIf="item.type === 'long-answer'"
        class="input-field"
        mode="md"
        placeholder="{{ item.required === true ? 'Required' : 'Optional' }}"
        [(ngModel)]="item.value"
        autocapitalize="sentences"
      >
      </ion-textarea>

      <!-- Dropdown -->
      <ion-select
        *ngIf="item.type === 'dropdown'"
        interface="popover"
        class="select-outline dropdown-option"
        [(ngModel)]="item.value"
      >
        <ion-select-option style="min-width: 150px" *ngFor="let opt of item.options">
          {{ opt.label }}
        </ion-select-option>
      </ion-select>

      <!-- Multiple choice -->
      <ion-list *ngIf="item.type === 'multiple-choice'" style="width: 100%" class="transparent-bg">
        <ion-radio-group [(ngModel)]="item.value">
          <ion-item *ngFor="let opt of item.options">
            <ion-radio slot="start" style="margin: 0 12px 0px -8px" [value]="opt.label"></ion-radio>
            <ion-label text-wrap>{{ opt.label }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-list>

      <!-- Multiselect -->
      <ion-list *ngIf="item.type === 'multiselect'" style="width: 100%" class="transparent-bg">
        <ion-item *ngFor="let opt of item.options; let j = index">
          <ion-checkbox
            slot="start"
            style="margin: 0 12px 0px -8px"
            (ionChange)="didToggleCheckbox(item)"
            [(ngModel)]="opt.selected"
          ></ion-checkbox>
          <ion-label text-wrap>{{ opt.label }}</ion-label>
        </ion-item>
      </ion-list>

      <!-- File upload -->
      <div *ngIf="item.type === 'file-upload'">
        <div *ngIf="uploadingAtIndex === i">
          <ion-button fill="clear">
            <ion-spinner name="crescent" style="margin-right: 10px"></ion-spinner>
            Uploading...
          </ion-button>
        </div>

        <div *ngIf="uploadingAtIndex !== i">
          <div *ngIf="!item.value">
            <label for="response-input-file-upload" class="file-upload">
              <ion-icon name="cloud-upload" class="icon-padded"></ion-icon>Upload file
            </label>
            <input id="response-input-file-upload" type="file" (change)="detectResponseUpload($event, i)" />
          </div>
          <div *ngIf="item.value" class="flex-space-between">
            <ion-input
              class="input-field"
              mode="md"
              [value]="getLabelForFilename(item.value)"
              [disabled]="true"
              readonly
            >
            </ion-input>
            <ion-button fill="clear" (click)="downloadFile(item.value)" class="btn-large" shape="round">
              <ion-icon name="cloud-download"></ion-icon>
            </ion-button>
            <ion-button fill="clear" color="danger" (click)="item.value = null" class="btn-large" shape="round">
              <ion-icon name="close-outline"></ion-icon>
            </ion-button>
          </div>
        </div>
      </div>
    </ion-row>
  </div>
</div>
<ion-list *ngIf="layout === 'list'" lines="none" class="no-padding">
  <ion-item style="align-items: center" *ngFor="let item of responseInputFields; let i = index">
    <ion-label>
      <p>
        {{ item.label }}
        <span
          *ngIf="item.required && !isReadOnly"
          class="question-accessory"
          style="color: #a70707"
          ngxTippy
          data-tippy-content="Response required"
          >*</span
        >
      </p>
    </ion-label>

    <ng-container *ngIf="isReadOnly">
      <ion-label>
        <p *ngIf="!item.value">
          <span style="opacity: 0.5">N/A</span>
        </p>
        <span *ngIf="item.value && !isLink(item.value)">
          {{ item.value }}
        </span>
        <a *ngIf="item.value && isLink(item.value)" [href]="item.value" target="_blank">
          <ion-icon name="open"></ion-icon>
          {{ item.value }}
        </a>
      </ion-label>
    </ng-container>

    <ng-container *ngIf="!isReadOnly">
      <!-- Checkbox input -->
      <ion-checkbox
        *ngIf="item.type === 'checkbox'"
        color="light"
        [ngModel]="!!item.value"
        (ngModelChange)="item.value = $event; didChange.emit()"
        slot="end"
        style="margin: 0"
      >
      </ion-checkbox>

      <span
        *ngIf="showPublicOption && item.public === false"
        class="question-accessory"
        ngxTippy
        data-tippy-content="Response won't be shared on your profile"
      >
        <ion-icon name="eye-off-outline"></ion-icon>
      </span>

      <!-- Short answer input -->
      <ion-input
        *ngIf="!item.type || item.type === 'short-answer' || item.type === 'user-attribute'"
        placeholder="{{ item.required === true ? 'Required' : 'Optional' }}"
        mode="md"
        [(ngModel)]="item.value"
        [disabled]="item.fieldKey"
        (ionBlur)="didChange.emit()"
        autocapitalize="sentences"
      >
      </ion-input>

      <!-- Long answer input -->
      <ion-textarea
        *ngIf="item.type === 'long-answer'"
        mode="md"
        placeholder="{{ item.required === true ? 'Required' : 'Optional' }}"
        [(ngModel)]="item.value"
        (ionBlur)="didChange.emit()"
        autocapitalize="sentences"
        style="margin: 0"
      >
      </ion-textarea>

      <!-- Dropdown -->
      <ion-select
        *ngIf="item.type === 'single-select' || item.type === 'multi-select'"
        interface="popover"
        class="select-outline"
        [(ngModel)]="item.value"
        (ngModelChange)="didChange.emit()"
        slot="end"
        [multiple]="item.type === 'multi-select'"
      >
        <ion-select-option style="min-width: 150px" *ngFor="let opt of item.options">
          {{ opt }}
        </ion-select-option>
      </ion-select>

      <!-- Multiple choice -->
      <ion-list *ngIf="item.type === 'multiple-choice'" style="width: 100%" class="transparent-bg" slot="end">
        <ion-radio-group [(ngModel)]="item.value">
          <ion-item *ngFor="let opt of item.options" (click)="didChange.emit()">
            <ion-radio slot="start" style="margin: 0 12px 0px -8px" [value]="opt.label"></ion-radio>
            <ion-label text-wrap>{{ opt.label }}</ion-label>
          </ion-item>
        </ion-radio-group>
      </ion-list>

      <!-- Multiselect -->
      <!-- <ion-list *ngIf="item.type === 'multi-select'" style="width: 100%" class="transparent-bg" slot="end">
        <ion-item *ngFor="let opt of item.options; let j = index">
          <ion-checkbox
            slot="start"
            style="margin: 0 12px 0px -8px"
            (ionChange)="didToggleCheckbox(item); didChange.emit()"
            [(ngModel)]="opt.selected"
          ></ion-checkbox>
          <ion-label text-wrap>{{ opt.label }}</ion-label>
        </ion-item>
      </ion-list> -->

      <!-- File upload -->
      <div *ngIf="item.type === 'file-upload'">
        <div *ngIf="uploadingAtIndex === i">
          <ion-button fill="clear">
            <ion-spinner name="crescent" style="margin-right: 10px"></ion-spinner>
            Uploading...
          </ion-button>
        </div>

        <div *ngIf="uploadingAtIndex !== i">
          <div *ngIf="!item.value">
            <label for="response-input-file-upload" class="file-upload">
              <ion-icon name="cloud-upload" class="icon-padded"></ion-icon>Upload file
            </label>
            <input id="response-input-file-upload" type="file" (change)="detectResponseUpload($event, i)" />
          </div>
          <div *ngIf="item.value" class="flex-space-between">
            <ion-input
              class="input-field"
              mode="md"
              [value]="getLabelForFilename(item.value)"
              [disabled]="true"
              readonly
            >
            </ion-input>
            <ion-button fill="clear" (click)="downloadFile(item.value)" class="btn-large" shape="round">
              <ion-icon name="cloud-download"></ion-icon>
            </ion-button>
            <ion-button fill="clear" color="danger" (click)="item.value = null" class="btn-large" shape="round">
              <ion-icon name="close-outline"></ion-icon>
            </ion-button>
          </div>
        </div>
      </div>
    </ng-container>
  </ion-item>
</ion-list>
